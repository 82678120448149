import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import metaverse from '../images/blastcat-nft.jpg'
// import fun from '../images/fun.jpg'

const NFT = () => {
    return (
        <div id='metaverse' className='about-section' >
            <Container data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <div className='text-center'>
                    <h3 className='section-title'>Enter the BlastCat Metaverse</h3>
                </div>
                <Row>
                    <Col md={6} className='mb-4'>
                        <div className='section-inner' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                            <div>
                                <p>The utility of the BlastCat token won't stop there!</p>
                                <p>100% of the NFT Royaly fee is used for marketing and community rewards.</p>
                                <p>Beyond the basics, the BlastCat project is using innovative ways to expand while reaching new users, investors and traders.</p>
                                <p>What you can expect from us:</p>
                                <ul className='text-start'>
                                    <li>Competitions and contests (Twitter, Tiktok...)</li>
                                    <li>BlastCat NFT launch</li>
                                    <li>BlastCat Portfolio Tracker</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className='mb-4'>
                        <div className='token-image' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                            <img src={metaverse} alt='BlastCat' />
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default NFT
